import styled from 'styled-components';
import { BreadCrumb } from 'primereact/breadcrumb';
import { colours, fontSize, lineHeight } from 'styles/styles';

export const PBreadCrumbStyle = {
  BreadCrumb: styled(BreadCrumb)`
    border: none;
    padding: 0px;

    &.p-breadcrumb.p-component > ol > li {
      .p-menuitem-link {
        padding: 0;
        cursor: default;
        &:focus,
        &:focus-within,
        &:active {
          background: none;
        }
      }
      .p-menuitem-text {
        color: ${colours.neutralGrey06};
        font-size: ${fontSize.fontSizeSM};
        line-height: ${lineHeight.lineHeightMD};
      }
      &.active {
        .p-menuitem-text {
          color: ${colours.neutralCharcoal};
          font-weight: 500;
        }
      }
      &.p-menuitem-separator {
        font-size: 12px;
        color: ${colours.neutralGrey05};
      }
    }

    &.breadcrumb-home-hidden > ol > li.p-menuitem-separator:first-child {
      display: none;
    }
  `,
};
