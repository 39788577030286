import styled from 'styled-components';
import { boxShadows, colours } from 'styles/styles';
import { PDropdown } from 'components/prime/PDropdown/PDropdown';

export const ColumnTypeStyle = {
  Container: styled.div`
    position: relative;
  `,
  CustomSelector: styled(PDropdown)`
    min-height: 36px !important;
    min-width: 160px !important;
    & .p-dropdown-label {
      padding: 1px 12px 0 40px !important;
    }
    & .p-dropdown-trigger {
      padding: 2px 0 0 0;
    }
    &:focus,
    &.p-inputwrapper-focus,
    &:focus-within {
      box-shadow: ${boxShadows.focused} !important;
      border: 1px solid ${colours.primaryBlue} !important;
    }
    &.p-disabled {
      opacity: 1;
      background: ${colours.neutralGreyBg} !important;
      .p-dropdown-label {
        color: ${colours.neutralGrey04} !important;
      }
      .p-dropdown-trigger {
        color: ${colours.neutralGrey04};
      }
    }
  `,
  IconContainer: styled.div<{ disabled?: boolean }>`
    position: absolute;
    top: 0;
    height: 36px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ disabled }) =>
      disabled &&
      `
      svg {
        & > path {
          fill: ${colours.neutralGrey04};
        }
      }
    `}
  `,
};
