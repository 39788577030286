import styled from 'styled-components';
import { colours } from 'styles/styles';

export const HeyYabbleCountStepTableHeaderStyle = {
  Container: styled.div`
    background-color: ${colours.neutralGrey01};
    height: 40px;
    display: flex;
    font-weight: 600;
    color: ${colours.neutralGrey06};
  `,
  CheckContainer: styled.div`
    width: 40px;
    border-right: 1px solid ${colours.neutralGrey03};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ItemContainer: styled.div`
    flex: 1;
    padding: 0 12px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 24px;
  `,
  WidthItem: styled.div`
    width: 96px;
    border-left: 1px solid ${colours.neutralGrey03};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px;
    font-size: 12px;
    line-height: 24px;
  `,
  DropdownsItem: styled.div`
    width: 400px;
    border-left: 1px solid ${colours.neutralGrey03};
    padding: 0 12px;
    font-size: 12px;
    line-height: 24px;
  `,
};
