import { HStack } from 'components/layouts/primitives/Stack/Stack';
import styled from 'styled-components';
import { colours, gap } from 'styles/styles';

export const MapStepDataTypeDataStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Item: styled.div<{ type?: 'popover' | 'modal' }>`
    display: flex;
    align-items: flex-start;
    ${({ type }) => type === 'popover' && `flex-direction: column;`}
    gap: 16px;
  `,
  TitleSection: styled(HStack)`
    display: flex;
    align-items: center;
    gap: ${gap.gap2};
    width: 220px;
  `,
  ContentSection: styled.div<{ type?: 'popover' | 'modal' }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    ${({ type }) => type === 'popover' && `padding-left: 28px;`}
    gap: 8px;
  `,
  Subtitle: styled.h6`
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    margin: 0;
    color: ${colours.neutralCharcoal};
  `,
  Text: styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${colours.neutralBody};
  `,
  TextBold: styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${colours.neutralCharcoal};
  `,
  SecondBox: styled.div`
    background: ${colours.neutralGrey01};
    border-radius: 4px;
    padding: 8px;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: ${colours.neutralBody};
    }
  `,
};
