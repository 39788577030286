import React, { useMemo, useState } from 'react';
import { AutoComplete, AutoCompleteProps } from 'primereact/autocomplete';
import { PAutoCompleteStyle as S } from './PAutoComplete.style';
import { useClassNames } from 'hooks/useClassNames';
import { Icon } from 'components/Icon/Icon';
import { PDropdownPropsSeverity } from '../PDropdown/PDropdown';
import { Text } from 'components/Text/Text';

export const PAutoComplete = React.forwardRef<
  AutoComplete,
  AutoCompleteProps & {
    invalid?: boolean;
    severity?: PDropdownPropsSeverity;
    containerClassName?: string;
    fixedWidth?: boolean;
  }
>((props, ref) => {
  const [overlayVisible, setOverlayVisible] = useState(false);

  const cleanProps = useMemo(
    () => (({ invalid, fixedWidth, containerClassName, ...o }: any) => o)(props),
    [props]
  );

  const classNames = useClassNames(
    {
      'p-invalid': props.invalid,
      'overlay-panel-visible': overlayVisible,
      'is-disabled': props.disabled,
      [`p-autocomplete-${props.severity || 'outlined'}`]: true,
    },
    props.className
  );

  const panelClassName = useClassNames({ 'fixed-width': props.fixedWidth }, props.panelClassName);

  return (
    <S.Container className={props.containerClassName}>
      <S.AutoComplete
        itemTemplate={(i) => (
          <Text ellipsis wordBreak="break-all">
            {props.field ? i[props.field] : i}
          </Text>
        )}
        {...cleanProps}
        onShow={() => {
          props.onShow?.();
          setOverlayVisible(true);
        }}
        onHide={() => {
          props.onHide?.();
          setOverlayVisible(false);
        }}
        dropdownIcon={<Icon icon="chevronDownLine" />}
        className={classNames}
        panelClassName={panelClassName}
        ref={ref}
      />
      <Icon icon="alertCircleSolid" className="p-dropdown-invalid-icon" />
    </S.Container>
  );
});
