import { useClassNames } from 'hooks/useClassNames';
import { Checkbox, CheckboxProps } from 'primereact/checkbox';
import React, { ReactNode, useMemo } from 'react';
import { PCheckboxStyle as S } from './PCheckbox.style';
import { Icon } from 'components/Icon/Icon';

export type PCheckboxProps = Omit<CheckboxProps, 'checked'> & {
  label?: string | ReactNode;
  labelClassName?: string;
  invalid?: boolean | null;
  checked?: boolean | null;
  indeterminate?: boolean | null;
  description?: string;
};

export const PCheckboxIcon: React.FC<Pick<PCheckboxProps, 'indeterminate'>> = ({ indeterminate }) => {
  return (
    <Icon
      icon={indeterminate ? 'minusLine' : 'checkLine'}
      className="checkbox-custom-icon"
      size={indeterminate ? 13 : 12}
    />
  );
};

export const PCheckbox = React.forwardRef<Checkbox, PCheckboxProps>((props, ref) => {
  const cleanProps = useMemo(
    () => (({ labelClassName, checked, indeterminate, description, ...o }: any) => o)(props),
    [props]
  );
  const checkboxClasses = useClassNames(
    {
      'p-invalid': props.invalid,
      'indeterminate-check': props.indeterminate,
      'no-label': !props.label,
    },
    props.className
  );
  const inputId = useMemo(
    () => props.inputId || props.id || (Math.random() * 100000).toString(),
    [props.inputId, props.id]
  );
  return (
    <S.CheckboxWrapper>
      <S.Checkbox
        {...cleanProps}
        checked={props.checked || false}
        className={checkboxClasses}
        inputId={inputId}
        ref={ref}
        icon={<PCheckboxIcon indeterminate={props.indeterminate} />}
      />
      {props.label && (
        <S.Label className={props.labelClassName} htmlFor={inputId}>
          {props.label}
          {props.description && <span className="description">{props.description}</span>}
        </S.Label>
      )}
    </S.CheckboxWrapper>
  );
});
