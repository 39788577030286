import styled from 'styled-components';
import { InputSwitch } from 'primereact/inputswitch';
import { borders, colourPalette, fontSize, gap, hexToRGBA, lineHeight, shadow } from 'styles/styles';

export const PInputSwitchStyle = {
  Container: styled.div<{ $toggleAlignment?: 'right' | 'left'; $disabled?: boolean; $hasTexts?: boolean }>`
    width: ${({ $toggleAlignment, $hasTexts }) =>
      $toggleAlignment === 'right' && $hasTexts ? '100%' : 'fit-content'};
    display: flex;
    align-items: flex-start;
    gap: ${gap.gap2};
    .p-input-switch-main {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      align-items: flex-start;
      order: ${({ $toggleAlignment }) => ($toggleAlignment !== 'right' ? '2' : '1')};
      p {
        margin: 0;
        font-size: ${fontSize.fontSizeSM};
        font-weight: 400;
        line-height: ${lineHeight.lineHeightMD};
        color: ${({ $disabled }) => (!$disabled ? colourPalette.neutral600 : colourPalette.neutral300)};
      }
    }
    .p-input-switch {
      height: 20px;
      order: ${({ $toggleAlignment }) => ($toggleAlignment !== 'right' ? '1' : '2')};
      ${({ $hasTexts }) => ($hasTexts ? 'margin-top: 3px;' : '')}
    }
    .p-input-switch-description {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: ${({ $disabled }) => (!$disabled ? colourPalette.neutral400 : colourPalette.neutral300)};
    }
  `,

  InputSwitch: styled(InputSwitch)`
    width: 36px;
    height: 20px;
    .p-inputswitch-slider {
      border-radius: 10px;
      background: ${colourPalette.neutral300};
      border: none;
      &::before {
        width: 16px;
        height: 16px;
        box-shadow: ${shadow.shadowDefault};
        margin: 0;
        top: 50%;
        left: 2px;
        transform: translateY(-50%);
        background: ${colourPalette.neutral0};
      }
    }
    &:hover {
      .p-inputswitch-slider {
        background: ${colourPalette.neutral400};
      }
    }
    &:focus,
    &:active,
    &:focus-within {
      .p-inputswitch-slider {
        outline: ${borders.borderMD} solid ${colourPalette.neutral0};
        box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
      }
    }
    &.p-highlight {
      .p-inputswitch-slider {
        background: ${colourPalette.blue500};
        &::before {
          left: 18px;
          transform: translate(0, -50%);
        }
      }
      &:hover {
        .p-inputswitch-slider {
          background: ${colourPalette.blue600};
        }
      }
    }
    &.p-disabled {
      opacity: 1;
      .p-inputswitch-slider {
        background: ${colourPalette.neutral100};
      }
      &.p-inputswitch-checked .p-inputswitch-slider {
        background: ${colourPalette.blue200};
      }
    }
    &.p-disabled.p-highlight {
      .p-inputswitch-slider {
        background: ${colourPalette.blue200};
      }
    }
  `,
};
